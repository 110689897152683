<template>
  <DataGrid
    :card="card"
    :display="display"
    :showDefaultGear="false"
    :pagination="pagination"
    :search="searchText"
    :items="departments"
    :isLoading="isLoading"
    @delete="deleteDepartment"
    @update:item="updateDepartment"
    @update:pagination="getDepartments"
    @update:search="searchHandler"
  >
    <template #header>
      <div class="dictionaries-header">
        <v-btn
          depressed
          rounded
          text
          router
          class="header-navigation-button"
          v-can:DICT_READ
          :to="{ name: 'categories' }"
        >
          {{ $t("menu.category") }}
        </v-btn>
        <v-btn
          depressed
          rounded
          text
          router
          class="header-navigation-button"
          v-can:DICT_READ
          :to="{ name: 'organizations' }"
        >
          {{ $t("menu.organizations") }}
        </v-btn>
        <v-btn
          class="header-navigation-button"
          color="navigationPageButtons white--text"
          rounded
          v-can:DICT_READ
        >
          {{ $t("menu.departments") }}
        </v-btn>
        <v-btn
          depressed
          rounded
          text
          router
          v-can:DICT_READ
          class="header-navigation-button"
          :to="{ name: 'defaultTags' }"
        >
          {{ $t("menu.defaultTags") }}
        </v-btn>
      </div>
    </template>
    <template #toolbar>
      <v-btn
        v-if="hasUserPermissionsToEdit"
        large
        icon
        text
        @click="addNewElement"
      >
        <IconBox color="iconButton" :tooltip="$t('dictionary.newValue')">
          stem-dictionaryPlus
        </IconBox>
      </v-btn>
    </template>
  </DataGrid>
</template>

<script>
import DataGrid from "../components/Shared/DataGrid";
import DepartmentCard from "../components/Contacts/DepartmentCard";
import DepartmentsService from "../services/DepartmentsService.js";
import { mapProps } from "@/store";
import { EmitSuccess, EmitWarning } from "../eventBus";
import { mapGetters } from "vuex";

export default {
  components: {
    DataGrid,
  },
  data() {
    return {
      card: DepartmentCard,
      departments: [],
      isLoading: false,
      display: "inline",
      searchText: "",
    };
  },
  created() {
    this.getDepartments();
  },
  computed: {
    ...mapGetters("settings", ["isMobile", "getUserRestrictions"]),
    ...mapProps(["pagination"], {
      options: "departmentOptions",
      action: "saveDepartmentOption",
    }),
    filtersDto() {
      return {
        search: this.searchText,
      };
    },
    hasUserPermissionsToEdit() {
      const userPermissions = this.getUserRestrictions
        ? this.getUserRestrictions.global
        : [];

      return userPermissions.some((item) => item.code === "DICT_WRITE");
    },
  },
  watch: {
    filtersDto: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.pagination.page = 1;
        this.getDepartments();
      },
      deep: true,
    },
  },
  methods: {
    getDepartments(pagination) {
      if (this.isLoading) return;
      this.isLoading = true;
      return DepartmentsService.GetDepartments({
        ...this.filtersDto,
        ...this._getPagination(pagination),
      })
        .then((res) => {
          this.departments = res.data;
          this.pagination = {
            ...this.pagination,
            page: res.pagination.currentPage,
            itemsPerPage: res.pagination.itemsPerPage,
            itemsLength: res.pagination.totalItems,
            pageStart:
              (res.pagination.currentPage - 1) * res.pagination.itemsPerPage,
            pageStop: res.pagination.currentPage * res.pagination.itemsPerPage,
            pageCount: Math.ceil(
              res.pagination.totalItems / res.pagination.itemsPerPage
            ),
          };
        })
        .finally(() => (this.isLoading = false));
    },
    deleteDepartment(id) {
      if (id) {
        DepartmentsService.DeleteDepartment(id).then((response) => {
          if (response) {
            EmitSuccess(this.$t("contacts.deleteSuccessDpt"));
            this.getDepartments();
          }
        });
      } else {
        const index = this.departments.map((e) => e.id).indexOf(id);
        this.departments.splice(index, 1);
      }
    },
    updateDepartment(id, model) {
      if (id) {
        DepartmentsService.EditDepartment({
          id: id,
          text: model.text,
        }).then((response) => {
          if (response) {
            const index = this.departments.map((e) => e.id).indexOf(id);
            this.departments[index].text = response.departmentName;
            EmitSuccess(this.$t("contacts.editSuccessDpt"));
          } else {
            EmitWarning(this.$t("dictionary.uniqueValue"));
          }
        });
      } else {
        DepartmentsService.AddDepartment(model.text).then((response) => {
          if (response) {
            const index = this.departments.map((e) => e.text).indexOf("");
            this.departments[index].text = response.departmentName;
            this.departments[index].id = response.departmentId;
            EmitSuccess(this.$t("contacts.createSuccessDpt"));
            this.getDepartments();
          } else {
            EmitWarning(this.$t("dictionary.uniqueValue"));
            const index = this.departments.map((e) => e.id).indexOf(id);
            this.departments.splice(index, 1);
          }
        });
      }
    },
    _getPagination(pagination = this.pagination) {
      return {
        pageNumber: pagination.page,
        pageSize: pagination.itemsPerPage,
      };
    },
    searchHandler(search) {
      this.pagination.page = 1;
      this.searchText = search;
    },
    addNewElement() {
      if (this.departments.map((e) => e.text).includes("")) return;
      this.departments.unshift({ id: undefined, text: "" });
    },
  },
};
</script>

<style scoped lang="scss">
.dictionaries-header {
  .header-navigation-button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
</style>
