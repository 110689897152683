<template>
  <div class="departament-card">
    <v-card
      v-if="edit"
      hover
      display
      :flat="true"
      @click="$emit('click')"
      :style="{ borderLeft: `solid ${color}  5px` }"
    >
      <v-card-text
        class="department-card-text"
        v-bind:class="isMobile && 'mobile'"
      >
        <v-layout @keydown.enter="$refs.editName.blur()" align-center>
          <v-flex xs11>
            <v-text-field
              :rules="[valueRequired]"
              :placeholder="$t('contacts.departmentName')"
              @blur="stopEdition($event)"
              v-model.trim="actualName"
              color="fields"
              ref="editName"
            ></v-text-field>
          </v-flex>
          <v-flex xs1>
            <v-btn icon text @click.stop="deleteRow">
              <IconBox color="iconButton" :tooltip="$t('common.cancel')">
                fa-times
              </IconBox>
            </v-btn>
            <v-btn
              v-if="id"
              icon
              text
              @click.stop="isConfirmDeleteModalShown = true"
            >
              <IconBox color="iconButton" :tooltip="$t('common.delete')">
                stem-delete
              </IconBox>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
      <ConfirmModal
        v-model="isConfirmDeleteModalShown"
        @confirm="deleteMessage"
        @cancel="edit = false"
      ></ConfirmModal>
    </v-card>
    <v-card
      v-else
      hover
      display
      :flat="true"
      @click="$emit('click')"
      :style="{ borderLeft: `solid ${color}  5px` }"
    >
      <v-card-text
        @click="startEdition($event)"
        class="department-card-text"
        v-bind:class="isMobile && 'mobile'"
      >
        <v-layout align-center class="textColor--text">
          <v-flex xs11 id="department-text-wrapper">
            <TooltipTruncate color="textColor">{{ text }}</TooltipTruncate>
          </v-flex>
          <v-flex xs1 id="delete-icon-wrapper">
            <v-btn icon text @click.stop="isConfirmDeleteModalShown = true">
              <IconBox
                v-if="hasUserPermissionsToEdit"
                color="iconButton"
                :tooltip="$t('common.delete')"
              >
                stem-delete
              </IconBox>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
      <ConfirmModal
        v-model="isConfirmDeleteModalShown"
        @confirm="deleteMessage"
      ></ConfirmModal>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ConfirmModal from "@/components/Shared/ConfirmModal";

export default {
  components: {
    ConfirmModal,
  },
  props: {
    id: { type: Number },
    text: { type: String, required: true },
    display: {
      type: String,
    },
    color: { type: String, default: "#67B1E2" },
  },
  data() {
    return {
      valueRequired: (v) => !!v.trim() || this.$t("rules.required"),
      isConfirmDeleteModalShown: false,
      itemIdToDelete: null,
      actualName: "",
      edit: false,
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(newVal) {
        if (typeof newVal === "undefined") {
          this.startEdition();
        }
      },
    },
  },
  methods: {
    deleteRow() {
      if (this.id) {
        this.edit = false;
      } else {
        this.deleteMessage();
      }
    },
    deleteMessage() {
      this.$emit("delete");
      this.edit = false;
    },
    startEdition() {
      if (this.edit === true || !this.hasUserPermissionsToEdit) return;
      this.edit = true;
      this.actualName = this.text;
      this.$nextTick(() => this.$refs.editName.focus());
    },
    stopEdition(event) {
      window.getSelection().removeAllRanges();
      if (event.relatedTarget !== null) return;

      if (
        (this.text !== this.actualName && this.actualName && this.id) ||
        (this.actualName && !this.id)
      ) {
        this.$emit("edit", {
          text: this.actualName,
        });
        this.edit = false;
      } else if (!this.actualName && !this.id) {
        this.deleteMessage();
      } else if (this.id && this.actualName) {
        this.edit = false;
      }
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile", "getUserRestrictions"]),
    hasUserPermissionsToEdit() {
      const userPermissions = this.getUserRestrictions
        ? this.getUserRestrictions.global
        : [];

      return userPermissions.some((item) => item.code === "DICT_WRITE");
    },
  },
};
</script>

<style scoped lang="scss">
.departament-card {
  box-shadow: $card-shadow;
}

.v-input.v-input--selection-controls.v-input--checkbox {
  margin: 0;
  padding: 0;
}
.v-input.v-input--selection-controls.v-input--checkbox
  ::v-deep
  .v-input--selection-controls__input {
  margin: 0;
}

.v-input.v-input--selection-controls.v-input--checkbox ::v-deep .v-icon {
  font-size: 20px;
}

.department-card-text #delete-icon-wrapper {
  max-width: none;
}

.department-card-text #department-text-wrapper {
  min-width: 0;
}
</style>
