import axios, { paramsSerializer } from "../plugins/http";
const apiUrl = "api/crmdepartments";

export default {
  GetDepartments(params) {
    return axios.get(apiUrl, {
      params,
      paramsSerializer,
    });
  },
  EditDepartment(departmentModel) {
    return axios.put(apiUrl, departmentModel);
  },
  DeleteDepartment(id) {
    return axios.delete(`${apiUrl}/${id}`);
  },
  AddDepartment(departmentName) {
    return axios.post(apiUrl, { text: departmentName });
  },
};
